body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.site-container {
  min-height: 100vh;
}

main {
  flex: 1;
}
.btn-primary,
.btn-outline-primary {
  background-color: #f0c040;
  color: black;
}
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}

.rating {
  color: #ffc000;
}

.product img {
  width: 100%;
  max-width: 400px;
}
.product-info {
  padding: 1 rem;
}
.img-large {
  max-width: 100%;
}
.img-thumbnail {
  height: 80px;
}
.small-container {
  max-width: 600px;
}
.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid #037917;
  color: #037917;
}

/*sidebar*/
.navbar-brand {
  font-weight: bold;
}
.side-navbar {
  width: 240px;
  height: 100%;
  position: absolute;
  left: -300px;
  background-color: #100901;
  transition: 0.5;
}
.site-container {
  transition: 0.4s;
}
.active-cont {
  margin-left: 240px;
}
.active-nav {
  left: 0;
}

.text-bold {
  font-weight: bold;
}
.btn_icon {
  font-size: 20px;
  color: rgb(1, 17, 20);
}

th,
td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}

th {
  background-color: rgb(117, 201, 250);
}

td {
  background-color: rgb(205, 235, 253);
}

/* Form {
  gap: 5px;
  display: flex;
} */

/* Form td:last-child {
  justify-content: space-evenly;
}

Form * {
  font-size: 28px;
}
input[type="text"] {
  height: 30px;
  font-size: 15px;
}
input[type="number"] {
  height: 30px;
  font-size: 15px;
}
textarea[name="comments"] {
  height: 30px;
} */
